<template>
    <teleport to="head">
        <title>Customers - Rovner Apps</title>
    </teleport>
    <Main>
        <template v-if="modalComponent">
            <component :is="modalComponent" />
        </template>
        <SearchBar />
        <div class="text-center table-container flex-1">
            <Table :rows="rows" />
        </div>
        <div class="pagination">
            <PerPage :pagination="pagination" @fetchData="fetchData" />
            <NoOfRows  :pagination="pagination" singular="Customer" plural="Customers"/>
            <Pagination :pagination="pagination" @fetchData="fetchData" />
        </div>
    </Main>
</template>

<script>
import Main from '../../components/Main';
import Table from './table/Index.vue';
import SearchBar from './SearchBar.vue';
import Pagination from '../../components/Pagination';
import PerPage from '../../components/PerPage';
import NoOfRows from '../../components/NoOfRows';
import Add from './modal/Add';
import PolicyAdd from '../policies/modal/Add';
import Delete from './modal/Delete';
import Edit from './modal/Edit';

export default {
    components: { Main, Table, Pagination, PerPage, SearchBar, Add, Delete, Edit, PolicyAdd, NoOfRows },

    data() {
        return {};
    },

    computed: {
        rows() {
            return this.$store.getters['customers/getTableRows'];
        },
        pagination() {
            return this.$store.getters['customers/getTablePagination'];
        },
        modalComponent() {
            return this.$store.state.customers.modal.component;
        },
    },

    methods: {
        async fetchData(obj) {
             if('perpage' in obj) {
                localStorage.setItem('customers_per_page', obj.perpage);
                delete obj.perpage
            }

            if (obj) {
                this.$store.commit('customers/setSearch', obj);
            }
            
            await this.$store.dispatch('customers/fetchTableRows', obj);
        },
    },
};
</script>
<style>
body {
     min-width: 700px;
}
</style>
