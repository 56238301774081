<template>
    <section class="table-section" ref="customerstable">
        <div v-click-outside="removeActiveStyle" v-if="activeRowId">
        </div>
        <table>
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Company</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <tr v-for="(row, i) in rows" :key="i" :class="activeRowId === row.id && 'row-active'">
                    <td style="padding-left: 0; width: 131px">
                        <div class="table-icons">
                            <a href="#" class="edit" @click.prevent="showEdit(row)" title="Edit Customer">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-.9 0 24 24" style="background: #047fff">
                                    <g clip-path="url(#clip0_46_43592)">
                                        <path
                                            d="M14.4803 7.38745C15.2954 8.1943 16.0289 8.91882 16.8277 9.7092C15.1812 11.3558 13.6 13.1013 11.8394 14.6162C11.3341 15.0608 10.2907 14.8961 9.49196 14.962C9.42675 14.962 9.24744 14.6162 9.24744 14.4515C9.29634 13.2989 9.49196 12.2615 10.4701 11.4052C11.8883 10.1538 13.1435 8.73769 14.4803 7.38745Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M15.074 6.98489C16.4108 5.27239 17.6986 5.43705 18.4811 7.19895C18.8397 8.0058 18.1551 8.38453 17.7149 8.81265C17.2585 9.27371 17.0628 8.71385 16.8183 8.49979C16.2152 8.0058 15.6446 7.47888 15.074 6.98489Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M14.21 13.1099V15.8927V15.9092C11.8137 15.9092 9.38473 15.9092 6.89058 15.9092C6.89058 13.4392 6.89058 11.0022 6.89058 8.48286C8.39033 8.48286 9.85748 8.417 11.3083 8.49933C12.3353 8.56519 12.8081 7.98887 13.3134 7.23142C13.1667 7.16555 13.0689 7.08322 12.9874 7.08322C10.8356 7.06676 8.70006 7.05029 6.54824 7.08322C5.89617 7.09969 5.53754 7.54428 5.53754 8.23587C5.53754 10.9034 5.53754 13.5545 5.53754 16.222C5.53754 16.9466 5.94508 17.3582 6.64605 17.3582C9.28692 17.3747 11.9115 17.3912 14.5524 17.3253C14.911 17.3088 15.5305 16.8148 15.5631 16.502C15.5794 16.3044 15.5957 16.1068 15.5957 15.9092H15.6283V12.2866H15.0251L14.21 13.1099Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_46_43592">
                                            <rect width="13.0413" height="11.5923" fill="white" transform="translate(5.53754 5.78223)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                            <a href="#" class="delete" @click.prevent="showDelete(row)" title="Delete Customer">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox=".6 -.9 24 24" style="background: #9f9f9f">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.9797 5.65798H17.5697V7.138H7.20953V5.65798H9.79957L10.5396 4.91797H14.2396L14.9797 5.65798ZM7.94954 16.7581V7.87801H16.8297V16.7581C16.8297 17.5722 16.1637 18.2382 15.3497 18.2382H9.42957C8.61555 18.2382 7.94954 17.5722 7.94954 16.7581Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                            <a href="#" @click.prevent="openFolder(row)" class="file" title="Open Folder">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 24 24" style="background: #ff764a">
                                    <path
                                        d="M18.2987 9.64472C18.2904 9.5035 18.2737 9.36227 18.2404 9.22961C18.0072 8.36514 17.2992 7.8302 16.3955 7.8302C14.6964 7.8302 12.9931 7.8302 11.2939 7.8302C11.2106 7.8302 11.1273 7.8302 11.0274 7.8302C11.0232 7.75317 11.0191 7.70181 11.0191 7.65474C10.9941 6.47358 10.2236 5.69471 9.07838 5.69043C8.27878 5.69043 7.47919 5.69043 6.68376 5.69043C5.55517 5.69471 4.77223 6.50354 4.76807 7.65902C4.76807 8.32234 4.76807 8.98567 4.76807 9.64472H18.2987Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M4.76807 9.97021C4.76807 11.8104 4.76807 13.6549 4.76807 15.4951C4.76807 16.6506 5.55933 17.4594 6.68376 17.4637C9.91962 17.468 13.1513 17.468 16.3872 17.4637C17.5116 17.4637 18.3029 16.6548 18.3029 15.4994C18.307 13.6549 18.307 11.8147 18.3029 9.97021H4.76807V9.97021Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                            <a href="#" class="add whitelist-clickoutside" title="Add Policy" @click.prevent="addPolicy(row)">
                                <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.4913" cy="11.9747" r="11.4634" fill="rgb(164, 82, 177)" />
                                    <g clip-path="url(#clip0_433_1639)">
                                        <path
                                            d="M11.7483 5.78955C12.775 6.76843 13.7715 7.77696 14.8435 8.71134C15.2209 9.03764 15.3115 9.36393 15.2813 9.79404C15.2511 10.2538 15.2813 10.7284 15.2813 11.2475C15.0549 11.114 14.8435 11.025 14.6774 10.8916C14.617 10.8471 14.6019 10.7433 14.6019 10.6543C14.5868 10.328 14.6019 10.0165 14.6019 9.66056C14.4811 9.66056 14.3905 9.66056 14.2848 9.66056C13.4544 9.66056 12.624 9.66056 11.7936 9.66056C11.386 9.66056 11.2803 9.55674 11.2803 9.14146C11.2803 8.35539 11.2803 7.58415 11.2803 6.79809C11.2803 6.69427 11.2803 6.60528 11.2803 6.48663C9.60437 6.48663 7.94356 6.48663 6.26765 6.48663C6.26765 9.98685 6.26765 13.4871 6.26765 17.0169C6.37334 17.0169 6.46393 17.0169 6.55452 17.0169C8.18513 17.0169 9.81575 17.0169 11.4615 17.0169C11.6879 17.0169 11.854 17.0614 11.9597 17.2691C12.0352 17.4026 12.156 17.5212 12.2768 17.6844C12.156 17.6844 12.0654 17.6992 11.9899 17.6992C10.1177 17.6992 8.24552 17.6992 6.37334 17.6992C5.73921 17.714 5.52783 17.5212 5.52783 16.9131C5.52783 13.4722 5.52783 10.0313 5.52783 6.59045C5.52783 6.20483 5.64862 5.9527 5.99588 5.78955C7.91336 5.78955 9.83085 5.78955 11.7483 5.78955ZM14.0886 8.94865C13.3638 8.28123 12.6995 7.67314 11.9748 7.00573C11.9748 7.68797 11.9748 8.31089 11.9748 8.94865C12.6693 8.94865 13.3487 8.94865 14.0886 8.94865Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M9.86113 11.4404C10.5406 11.4404 11.22 11.4404 11.8843 11.4404C12.0655 11.4404 12.2165 11.47 12.2467 11.6777C12.2769 11.9001 12.1108 11.9446 11.9447 11.9595C11.8843 11.9743 11.839 11.9743 11.7786 11.9743C10.4651 11.9743 9.15151 11.9743 7.83796 11.9743C7.64168 11.9743 7.4756 11.9298 7.4907 11.6925C7.4907 11.4552 7.65678 11.4255 7.85306 11.4255C8.50229 11.4404 9.18171 11.4404 9.86113 11.4404Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M11.0237 15.3262C10.5858 15.3262 10.1631 15.3262 9.75544 15.3262C9.09112 15.3262 8.44189 15.3262 7.77756 15.3262C7.59638 15.3262 7.4756 15.2817 7.4605 15.074C7.4454 14.8812 7.56619 14.7922 7.74737 14.7922C8.78915 14.7922 9.83093 14.7922 10.8878 14.7922C10.9331 14.9702 10.9784 15.1334 11.0237 15.3262Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M10.7066 13.1014C10.7066 13.2942 10.7066 13.4574 10.7066 13.6354C10.3896 13.6354 10.0876 13.6354 9.78562 13.6354C9.1062 13.6354 8.44188 13.6354 7.76245 13.6354C7.59637 13.6354 7.47559 13.5612 7.47559 13.3832C7.47559 13.2053 7.56618 13.1014 7.76245 13.1014C8.72874 13.1014 9.69503 13.1014 10.7066 13.1014Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.94008 10.2686C8.56262 10.2686 8.17007 10.2686 7.79261 10.2686C7.61143 10.2686 7.46045 10.2241 7.46045 10.0016C7.46045 9.80884 7.59633 9.74951 7.77751 9.74951C8.54753 9.74951 9.33264 9.74951 10.1027 9.74951C10.2838 9.74951 10.4197 9.80884 10.4197 10.0016C10.4197 10.2093 10.2838 10.2686 10.0876 10.2686C9.71009 10.2686 9.31754 10.2686 8.94008 10.2686Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.38141 8.69653C8.17003 8.69653 7.95866 8.69653 7.74728 8.69653C7.5661 8.69653 7.46041 8.62237 7.46041 8.4444C7.44532 8.26642 7.551 8.17743 7.71709 8.1626C8.15494 8.1626 8.59279 8.1626 9.03064 8.1626C9.21182 8.1626 9.3175 8.28125 9.28731 8.42956C9.25711 8.53338 9.13633 8.65204 9.03064 8.66687C8.81926 8.72619 8.60789 8.69653 8.38141 8.69653Z"
                                            fill="white"
                                        />
                                    </g>
                                    <rect x="12.7244" y="15.5803" width="1.05911" height="4.32767" transform="rotate(-90 12.7244 15.5803)" fill="white" />
                                    <rect x="14.3586" y="12.887" width="1.05911" height="4.32767" fill="white" />
                                    <defs>
                                        <clipPath id="clip0_433_1639">
                                            <rect width="11.9277" height="12.3694" fill="white" transform="translate(5.52783 5.78955)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>

                            <a href="#" class="note whitelist-clickoutside" title="View Policies" @click.prevent="moveToPolicy(row)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.6102" cy="12.0765" r="11.4634" fill="#52B15C" />
                                    <g clip-path="url(#clip0_438_12639)">
                                        <path
                                            d="M11.8672 5.89142C12.8939 6.87029 13.8904 7.87883 14.9624 8.81321C15.3398 9.1395 15.4304 9.46579 15.4002 9.89591C15.37 10.3557 15.4002 10.8303 15.4002 11.3494C15.1738 11.2159 14.9624 11.1269 14.7963 10.9934C14.7359 10.9489 14.7208 10.8451 14.7208 10.7561C14.7057 10.4298 14.7208 10.1184 14.7208 9.76242C14.6 9.76242 14.5094 9.76242 14.4037 9.76242C13.5733 9.76242 12.7429 9.76242 11.9125 9.76242C11.5049 9.76242 11.3992 9.6586 11.3992 9.24332C11.3992 8.45726 11.3992 7.68602 11.3992 6.89996C11.3992 6.79614 11.3992 6.70715 11.3992 6.5885C9.72327 6.5885 8.06246 6.5885 6.38654 6.5885C6.38654 10.0887 6.38654 13.5889 6.38654 17.1188C6.49223 17.1188 6.58282 17.1188 6.67341 17.1188C8.30403 17.1188 9.93464 17.1188 11.5804 17.1188C11.8068 17.1188 11.9729 17.1633 12.0786 17.3709C12.1541 17.5044 12.2749 17.6231 12.3957 17.7862C12.2749 17.7862 12.1843 17.8011 12.1088 17.8011C10.2366 17.8011 8.36442 17.8011 6.49223 17.8011C5.8581 17.8159 5.64673 17.6231 5.64673 17.015C5.64673 13.5741 5.64673 10.1332 5.64673 6.69232C5.64673 6.3067 5.76751 6.05456 6.11478 5.89142C8.03226 5.89142 9.94974 5.89142 11.8672 5.89142ZM14.2075 9.05051C13.4827 8.3831 12.8184 7.77501 12.0937 7.1076C12.0937 7.78984 12.0937 8.41276 12.0937 9.05051C12.7882 9.05051 13.4676 9.05051 14.2075 9.05051Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M14.6603 18.2608C13.6035 17.8456 12.8032 17.1633 12.3201 16.1251C12.003 15.428 11.8521 14.6716 11.8068 13.9152C11.7917 13.5889 11.8068 13.2478 11.8068 12.9067C12.0785 12.8919 12.3654 12.877 12.6372 12.8474C13.3317 12.7732 13.9507 12.5062 14.5245 12.1058C14.6603 12.0168 14.7358 12.0316 14.8566 12.1058C15.5209 12.4914 16.2004 12.8177 16.9855 12.9067C17.1667 12.9215 17.3478 12.9364 17.529 12.9364C17.7404 14.7161 17.2572 17.2968 14.7207 18.2608C14.6905 18.2608 14.6754 18.2608 14.6603 18.2608ZM14.2074 16.4959C14.9019 15.517 15.5964 14.5382 16.2759 13.5889C15.8682 13.5 15.5058 13.6038 15.219 13.9746C15.0076 14.2564 14.766 14.5085 14.5396 14.7755C14.4339 14.9089 14.3282 15.0573 14.2074 15.2204C13.9356 14.8941 13.6186 14.7606 13.226 14.82C13.1656 14.8348 13.0901 14.8644 13.0599 14.9089C13.0448 14.9534 13.075 15.0573 13.1052 15.0869C13.6337 15.4725 13.9658 15.9768 14.2074 16.4959Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M9.98003 11.5422C10.6595 11.5422 11.3389 11.5422 12.0032 11.5422C12.1844 11.5422 12.3354 11.5718 12.3656 11.7795C12.3958 12.0019 12.2297 12.0464 12.0636 12.0613C12.0032 12.0761 11.9579 12.0761 11.8975 12.0761C10.584 12.0761 9.27041 12.0761 7.95686 12.0761C7.76058 12.0761 7.5945 12.0316 7.6096 11.7943C7.6096 11.557 7.77568 11.5273 7.97196 11.5273C8.62118 11.5422 9.30061 11.5422 9.98003 11.5422Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M11.1426 15.428C10.7047 15.428 10.282 15.428 9.87434 15.428C9.21001 15.428 8.56079 15.428 7.89646 15.428C7.71528 15.428 7.59449 15.3835 7.5794 15.1759C7.5643 14.9831 7.68508 14.8941 7.86626 14.8941C8.90805 14.8941 9.94983 14.8941 11.0067 14.8941C11.052 15.0721 11.0973 15.2352 11.1426 15.428Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M10.8255 13.2033C10.8255 13.3961 10.8255 13.5593 10.8255 13.7372C10.5085 13.7372 10.2065 13.7372 9.90452 13.7372C9.2251 13.7372 8.56077 13.7372 7.88135 13.7372C7.71527 13.7372 7.59448 13.6631 7.59448 13.4851C7.59448 13.3071 7.68507 13.2033 7.88135 13.2033C8.84764 13.2033 9.81393 13.2033 10.8255 13.2033Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M9.05898 10.3705C8.68152 10.3705 8.28897 10.3705 7.91151 10.3705C7.73033 10.3705 7.57935 10.326 7.57935 10.1036C7.57935 9.91077 7.71523 9.85144 7.89641 9.85144C8.66642 9.85144 9.45153 9.85144 10.2215 9.85144C10.4027 9.85144 10.5386 9.91077 10.5386 10.1036C10.5386 10.3112 10.4027 10.3705 10.2064 10.3705C9.82899 10.3705 9.43644 10.3705 9.05898 10.3705Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.50031 8.7984C8.28893 8.7984 8.07756 8.7984 7.86618 8.7984C7.685 8.7984 7.57931 8.72424 7.57931 8.54626C7.56421 8.36829 7.6699 8.2793 7.83598 8.26447C8.27383 8.26447 8.71168 8.26447 9.14953 8.26447C9.33071 8.26447 9.4364 8.38312 9.4062 8.53143C9.37601 8.63525 9.25522 8.7539 9.14953 8.76873C8.93816 8.82806 8.72678 8.7984 8.50031 8.7984Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_438_12639">
                                            <rect width="11.9277" height="12.3694" fill="white" transform="translate(5.64673 5.89142)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.firstName }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.lastName }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.company }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.email }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.phone }}</div>
                    </td>
                    <td>
                        <div style="max-width: 230px" class="table-notes">{{ row.address }}</div>
                    </td>
                    <td>
                        <div :title="row.notes" class="table-notes">{{ row.notes }}</div>
                    </td>
                </tr>
            </tbody>
            <template v-else>
                <tr>
                    <td colspan="9">
                        <div class="text-black text-center py-4">No data found</div>
                    </td>
                </tr>
            </template>
        </table>
    </section>
</template>

<script>
export default {
    name: 'Table',
    props: ['rows'],
    data() {
        return {
            row: {},
        };
    },

    computed: {
        activeRowId: {
            get() {
               return this.$store.state.customers.activeRowId;
            },

            set(v) {
                this.$store.commit('customers/setActiveRowId', v);
            }
        },

        tableScroll() {
            return this.$store.state.customers.tableScroll;
        }
    },

    mounted() {
        const el = this.$refs['customerstable'];

        if(this.tableScroll) {
           el.scrollTop = this.tableScroll;
        }

         el.addEventListener('scroll', (e) => {
            if(this.tableScroll !==  e.currentTarget.scrollTop) {
                this.$store.commit('customers/setTableScroll', e.currentTarget.scrollTop)
            }
         })
    },

    methods: {
        showEdit(row) {
            this.$store.commit('customers/showEdit', row);
        },

        removeActiveStyle(e) {
          // table row buttons and policy modal are whitelisted
          const whitelisted = e.target.closest('.whitelist-clickoutside');
          // Make sure is in customer pages
          const customerPage = this.$route.name === 'customers';
          // Handle click on dropdown inside modal
          const policyModalVisible = this.$store.state.customers.modal.component === 'PolicyAdd'
          // prevent from emptying the activeRowId
          if(customerPage && !whitelisted && !policyModalVisible) {
              this.activeRowId = null;
          }
        },

        addPolicy({ id: customerId, address, company, fullName }) {
            this.activeRowId = customerId;

            this.$store.commit('policies/setDetails', {customerId, address, company, fullName, newBusiness: true,});
            this.$store.commit('customers/showPolicyAdd');
        },

        async showDelete(row) {
            this.loader(true);
            // Verify the customerId if still works June 9, 2022
            const { count } = await this.$store.dispatch('policies/count', { customerId: row.id });
            this.loader(false);
            this.row = row;

            if (count > 0) {
                let policy = 'policy';
                let is = 'is';
                if (count > 1) {
                    policy = 'policies';
                    is = 'are';
                }

                let text = `You are also deleting ${count} ${policy} that ${is} associated with this customer`;
                this.$store.commit('customers/setDeleteWarning', text);
            }
            this.deleteUser();
        },

        deleteUser() {
            this.$store.commit('customers/showDelete', this.row);
            this.row = {};
        },

        openFolder(row) {
            this.$store.dispatch('customers/openFolder',row)
        },

        moveToPolicy({id, company, fullName }) {
            this.activeRowId = id;

            this.$store.commit('policies/resetSearch');
            this.$store.commit('policies/setSearch', { company, fullName });
            this.$router.push('policies');
        },
    },
};
</script>
<style scoped lang="scss">
    section {
        table {
                .row-active {
                     background: #C7E2FF !important;
               }
          }
    }
</style>
