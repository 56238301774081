<template>
    <Modal type="Delete" @submitData="submitData" :isDelete="true" />
</template>

<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },
    methods: {
        async submitData() {
            this.loader();
            const { id } = this.$store.state.customers.modal.details;
            await this.$store.dispatch('customers/deleteTableRow', { id });
            this.loader(false);
            await this.$store.dispatch('customers/fetchTableRows');
            this.$store.commit('customers/hideModal');
        },
    },
};
</script>
