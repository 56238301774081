<template>
    <section class="flex search-bar">
       
        <div class="input-container" style="width: 170.36px">
            <label> Name </label>
            <input v-model.trim="search.fullName" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 170.36px">
            <label> Company </label>
            <input v-model.trim="search.company" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 170.36px">
            <label> Email  </label>
            <input v-model.trim="search.email" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 170.36px">
            <label> Notes </label>
            <input v-model.trim="search.notes" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 159.19px">
            <button type="button" class="reset w-full" @click="resetSearch">Reset</button>
        </div>
        <div class="input-container" style="width: 159.19px">
            <button  type="button" class="search w-full" @click="triggerSearch">Search</button>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        search() {
            return this.$store.getters['customers/getSearch'];
        },
          tableScroll() {
            return this.$store.state.customers.tableScroll;
        }
    },

    async mounted() {
        if(!this.tableScroll) {
           window.history.replaceState({}, document.title, window.location.pathname)
           let params = {};
            params = {...this.search, ...params};
           this.$store.dispatch('customers/fetchTableRows', params);
        }
           
    },

    methods: {
        async triggerSearch() {
            this.setQueryParams(this.search);
            // Set default page 0 
            await this.$store.dispatch('customers/fetchTableRows', {page: 0});
        },

        resetSearch() {
            this.$store.commit('customers/resetSearch');
            window.history.replaceState({}, document.title, window.location.pathname);
        },
    },
};
</script>

